<template>
    <div class="messages">
        <div class="filter clearfix">
            <div class="fL">
                <a-button class="btn active" style="margin-right:20px">审核</a-button>
                <a-button class="btn">反馈</a-button>
            </div>
            <div class="fR">
                 <a-date-picker class="datePicker">
                    <a-icon slot="suffixIcon" type="down" />
                    <!-- <a-icon type="down" /> -->
                 </a-date-picker>
            </div>
        </div>
        <div style="padding:20px 30px">
            <a-table :columns="columns" :dataSource="result" class="table" :rowKey="record => record.id">
                <template slot="operation" slot-scope="text"> 
                    <div class="iconGroup hoverColor_E csP_E">
                        <a-icon type="cloud-download" style="font-size:22px" class="mr20px" v-if="text.file_url" @click="downLoadFile(text)"/>
                        <a-icon type="form" class="fs20px" @click="$router.push({path:'/home/EditTask', query: {id: text.id}})"/>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
const columns = [
    {
        title: '课程名',
        dataIndex: 'title',
    },
    {
        title: '状态',
        dataIndex: 'status',
    },{
        title: '进程',
        dataIndex: 'total_subject',
    },
    {
        title: '操作',
       scopedSlots: { customRender: 'operation' },
    }
];
export default {
    name:'messages',
    data(){
        return{
            columns,
            result:[]
        }
    },
    methods:{

    }
}
</script>
<style lang="less" scoped>
    .filter{
        padding:30px 30px 0;
    }
    .btn{
        width: 105px;
        background: none;
        color: #5180e0;
        border: 1px solid #5180e0;
    }
    .datePicker{
        width: 180px;
        /deep/ .ant-input{
            color: #5180e0;
            background: none;
            font-size: 16px;
            height: 40px;
            border: 1px solid #5180e0;
        }
        /deep/ .anticon-calendar{
             color: #5180e0
        }
    }
</style>